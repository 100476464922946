@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
:root {
  --primary-color: #6e12f9;
  --black-color: #000;
}
html {
  zoom: 80%;
}
body {
  font-family: "Montserrat", sans-serif;
  background-image: url("/public/assets/img/bg.svg");
  /* background-repeat: no-repeat; */
  background-attachment: fixed;
  background-size: 300px 150px;
  /* background-size: cover; */
}
/* Hide the up and down arrows on number input */
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.ReactModalPortal .ReactModal__Overlay {
  z-index: 999;
  background-color: rgb(32 30 30 / 75%) !important;
}
/* For modern browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Internet Explorer */
input[type="number"] {
  -ms-inner-appearance: none;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.resetTableData {
  background-color: transparent;
  border: 2px solid #f7674c;
  padding: 10px 25px;
  color: #f7674c;
  border-radius: 5px;
}

.searchTableData {
  /* background-color: var(--primary-color); */
  padding: 10px 25px;
  border: none;
  color: #fff;
  border-radius: 5px;
  background-image: linear-gradient(#f7674c, #f47746);
}

.searchInputField:focus {
  outline: none;
  border: 2px solid #2684ff;
}

.searchInputField {
  padding: 6px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 100%;
}

.alignmentTable {
  align-items: center;
}

.customHeaderTable {
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 13px 6px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dashboardTable {
  width: 100%;
}

.merchantDetailsHeading {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 30px;
  text-decoration: underline;
  margin-top: 30px;
}

.loaderMngProps {
  position: fixed;
  z-index: 10;
  background: rgba(256, 256, 256, 0.6);
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
}

.loaderMngProps img {
  max-width: 120px;
}

.loaderMngProps .loadingLoaderMsg {
  font-size: 20px;
  margin-top: 20px;
}

.imageTextLoading {
  position: absolute;
  left: 37%;
  top: 40%;
}

.modalCloseBtn {
  position: absolute;
  /* right: -22px; */
  /* top: -15px; */
  font-size: 33px;
  background: transparent;
  border: none;

  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1001;
}
.modalCloseBtnOrder {
  position: absolute;
  right: 2px !important;
  top: -7px !important;
  font-size: 33px;
  background: transparent;
  border: none;
}
.modalUserImage img {
  /* width: 500px;
  height: 500px;
  object-fit: contain; */
  display: block;
  max-width: 100%;
  max-height: 800px;
  height: auto;
  margin: 0 auto;
}

.rotateBtnContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.rotateBtn {
  background: #f47746;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}
.rotateBtn:hover {
  background: #6f42c1;
}

.react-date-picker {
  display: inline-flex;
  position: relative;
  border: none;
  padding: 0px;
}

.react-date-picker__wrapper {
  padding: 4px;
  border-radius: 5px;
}

.UploadCSVDataButton {
  background-color: transparent;
  color: #6e12f9;
  border: 1px solid #6e12f9;
  border-radius: 5px;
  font-size: 19px;
  font-weight: 600;
  padding: 10px 45px;
  cursor: pointer;
}

.UploadCSVDataButton:hover {
  background-color: #6e12f9;
  color: #fff;
  border: 1px solid #6e12f9;
}

.fileCSVData {
  background-color: transparent;
  border: none;
  color: #6e12f9;
  font-size: 25px;
}

.fileCSVData a {
  color: #6e12f9;
}

.fileCSVData:hover {
  color: #2e0965;
}

.uploadCsvuplo label.csvLabel input[type="file"] {
  position: absolute;
  top: -1000px;
  opacity: 0;
}

.mngimag img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.uploadCsvuplo h4 {
  margin-bottom: 50px;
  text-align: center;
}

.basicDetailsUser h4 {
  margin-bottom: 50px;
  text-align: center;
}

.mngimag h4 {
  margin-bottom: 50px;
  text-align: center;
}

.selctcsvTech {
  display: flex;
  /* margin-top: 50px; */
  align-items: center;
  justify-content: center;
}

.mngimag {
  width: 100%;
  position: relative;
  /* height: 400px; */
  /* max-height: 500px;
  overflow-y: scroll;
  text-align: center; */
}
.mngimag .imagemsngOrder {
  margin-bottom: 20px;
}

.mngimag {
  margin-bottom: 15px;
  position: relative;
}

.viewoDoenload .btnviewImage {
  background-color: #f7674c;
  color: #fff;
  width: 40px;
  height: 40px;
  text-decoration: none;
  border-radius: 50px;
  margin: 10px;
  border: none;
  font-size: 20px;
}
.viewoDoenload .btnviewImage a {
  color: #fff;
}

.viewoDoenload {
  position: absolute;
  left: 15px;
}

.basicDetailsUser p {
  font-weight: 600;
  font-size: 18px;
}

.basicUnderOrderTable {
  border-bottom: 2px solid #a19797;
  margin-bottom: 30px;
}

.uploadFileMsg {
  background-color: green;
  width: 100%;
  position: absolute;
  top: 0px;
  padding: 10px;
}

.uploadFileMsg h5 {
  color: #fff;
  margin-bottom: 3px;
}

.btnAddCredit {
  /* background: #6E12F9; */
  padding: 8px 40px;
  font-weight: 600;
  color: #fff;
  background-image: linear-gradient(#f7674c, #f47746) !important;
}
.btnAddCredit:hover {
  color: #fff;
}
.btnUploadQuotationss {
  background: #f7674c;
  padding: 11px 25px;
  font-weight: 600;
  color: #fff;
  font-size: 13px;
}

.labelforSelectScg {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}

.form-control:focus {
  box-shadow: none;
}

.viewButtonMean {
  border: none;
  padding: 7px 20px;
  border-radius: 10px;
  font-weight: 500;
  border: 1px solid #ccc;
}

.btnapcpt {
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 6px 20px;
  font-size: 16px;
  margin-right: 15px;
}

.btnreject {
  background-color: transparent;
  color: red;
  border: 1px solid red;
  border-radius: 5px;
  padding: 6px 20px;
  font-size: 16px;
}

.confirmationbtn {
  display: flex;
  justify-content: space-around;
}

.cherigt {
  font-size: 20px;
  color: green;
}

.chewrong {
  font-size: 20px;
  color: red;
}

.attendanmodalmngpr .modal-content {
  max-width: fit-content;
  margin: auto;
}

.textacont:focus {
  border-color: #6e12f9 !important;
}

.textacont {
  border-radius: 5px !important;
  resize: none;
  border: 1px solid #645959 !important;
}

.attenactive {
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 6px 20px;
  font-size: 16px;
  max-width: 110px;
}

.atteninactive {
  background-color: transparent;
  color: red;
  border: none;
  border: 1px solid red;
  border-radius: 5px;
  padding: 6px 10px;
  font-size: 16px;
  max-width: 110px;
}

.attenSuspended {
  background-color: transparent;
  color: #b5b54e;
  border: none;
  border: 1px solid #b5b54e;
  border-radius: 5px;
  padding: 6px 10px;
  font-size: 16px;
  max-width: 110px;
}

.valuotpn {
  outline: none;
  border-radius: 5px;
  padding: 3px;
}

.wrapper_btnselt {
  display: inline-flex;
  background: #fff;
  height: 100px;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  padding: 20px 15px;
}

.wrapper_btnselt .option {
  background: #fff;
  height: 85%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  padding: 0 10px;
  /* border: 2px solid lightgrey; */
  transition: all 0.3s ease;
}

.option-1 {
  border: 2px solid green !important;
}

.option-2 {
  border: 2px solid red !important;
}

.option-3 {
  border: 2px solid #b5b54e !important;
}

.option-4 {
  border: 2px solid #198754 !important;
}

.option-5 {
  border: 2px solid #1f519b !important;
}

.wrapper_btnselt .option .dot {
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}

.wrapper_btnselt .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #0069d9;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}

.wrapper_btnselt input[type="radio"] {
  display: none;
}

.acceptlisth .cherigt {
  color: #fff !important;
}

.acceptlisth {
  border-color: green;
  background: green !important;
  color: #fff !important;
}

.rejectlisth .chewrong {
  color: #fff !important;
}

.rejectlisth {
  border-color: red;
  background: red !important;
  color: #fff !important;
}

.suspendedMng {
  border-color: #b5b54e;
  background: #b5b54e !important;
  color: #fff !important;
}

.approvedlisth .chewrong {
  color: #fff !important;
}

.approvedlisth {
  border-color: #198754;
  background: #198754 !important;
  color: #fff !important;
}

.duplicatelisth .chewrong {
  color: #fff !important;
}

.duplicatelisth {
  border-color: #1f519b;
  background: #1f519b !important;
  color: #fff !important;
}

#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot,
#option-3:checked:checked ~ .option-3 .dot,
#option-4:checked:checked ~ .option-4 .dot,
#option-5:checked:checked ~ .option-5 .dot {
  background: #fff;
}

#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before,
#option-2:checked:checked ~ .option-3 .dot::before,
#option-2:checked:checked ~ .option-4 .dot::before,
#option-2:checked:checked ~ .option-5 .dot::before {
  opacity: 1;
  transform: scale(1);
}

.option-1 {
  font-size: 20px;
  color: green;
}

.option-2 {
  font-size: 20px;
  color: red;
}

.option-3 {
  font-size: 20px;
  color: #b5b54e;
}

.option-4 {
  font-size: 20px;
  color: #198754;
}

.option-5 {
  font-size: 20px;
  color: #1f519b;
}

.activefosbtn {
  border: none;
  background: transparent;
}

.modalUserStatus {
  padding: 20px;
}
.modalUserPaymentStatus {
  padding: 20px;
  overflow-x: auto;
  height: auto;
  max-height: 85vh;
}
.modalUserPaymentStatusAddBrand {
  padding: 20px;
}
.modalUserPaymentStatusAddCoupon {
  padding: 20px;
  max-height: 100vh;
  overflow-x: auto;
}

.modalUserStatusQuatation {
  padding: 20px;
  max-height: 80vh;
  overflow-x: auto;
}

.pleaseSelectStatus {
  background-color: white;
  color: #000;
  padding: 10px;
  font-size: 20px;
}
.applicationForm {
  background: none;
  border: none;
  color: #6e12f9;
  font-size: 30px;
  margin-left: 25px;
}
.acceptDataCss {
  background: none;
  border: none;
}

.acceptDataCss a {
  color: #f7674c;
  font-size: 30px;
}
.orderDetailsSection .col-md-4:first-child {
  border: 1px solid #000;
  padding: 0px;
}
.orderDetailsSection .col-md-4:last-child {
  border: 1px solid #000;
  padding: 0px;
}
.orderDetailsSection .col-md-4:nth-child(2) {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 0px;
}
.orderDetailsSection .col-md-6 h4 {
  border-bottom: 1px solid #000;
  padding: 8px;
  color: #000;
  text-align: left;
  font-size: 19px;
}
/* .orderDetailsSection .basicDetailsUser{
  padding: 15px;
} */
.orderDetailsSection .basicDetailsUser .userDetailsTableInfo {
  width: 100%;
}
.orderDetailsSection .basicDetailsUser .userDetailsTableInfo tr th {
  border: 1px solid #000;
  padding: 5px;
}
.orderDetailsSection .basicDetailsUser .userDetailsTableInfo tr td {
  border: 1px solid #000;
  padding: 5px;
}
.custom-file-upload {
  position: relative;
  display: inline-block;
}

.custom-file-upload input[type="file"] {
  display: none;
}

.custom-file-upload label {
  background-color: #3498db;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  position: relative;
  display: inline-block;
}

.custom-file-upload label:hover {
  background-color: #2980b9;
}

.image-grid {
  display: grid;

  gap: 10px;
}

.uploaded-image-container {
  position: relative;
  display: inline-block;
}

.uploaded-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}
.orderUploadModal img {
  width: 100%;
  height: 150px;
  object-fit: contain;
}
.orderUploadModal {
  padding: 20px;
}
.custom-file-upload {
  text-align: center;
  margin-left: 10px;
}

.form-label {
  display: block;
  margin-bottom: 10px;
}
.menuListCheck {
  transition: all 0.6s;
}
.menuListCheck .submenu li {
  list-style: circle;
  padding: 0px;
  margin-left: 30px;
  line-height: 2;
}
.menu .subDropListMsng {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* change border radius for the tab , apply corners on top*/

#exTab3 .nav-pills > li > a {
  border-radius: 4px 4px 0 0;
}

#exTab3 .tab-content {
  padding: 5px 15px;
}
#exTab3 .nav-pills li {
  padding: 6px 10px;
}
#exTab3 .nav-pills li a {
  color: #000;
  font-weight: 600;
  text-decoration: none;
}
#exTab3 .nav-pills .active {
  background-color: #6e12f9;
}
#exTab3 .nav-pills .active a {
  color: #fff;
}
#exTab3 .nav-pills {
  background: #f2f2f2;
  padding: 5px;
}
.applyForLoan {
  display: flex;
}
.applyForLoan p {
  margin-right: 20px;
  font-weight: 600;
}
.customerLoanDetailsTable {
  width: 100%;
}
.customerLoanDetailsTable tr th {
  border: 1px solid #ccc;
  padding: 5px;
}
.customerLoanDetailsTable tr td {
  border: 1px solid #ccc;
  padding: 5px;
}
.changeProductPro label span {
  color: red;
}
.programDetailsDekho {
  color: #7b7474;
  font-size: 22px;
  margin-bottom: 10px;
}
.changeProductPro label {
  font-size: 13px;
  font-weight: 600;
}
.proPlusIcon svg {
  font-size: 20px;
  color: red;
  margin-top: 10px;
  cursor: pointer;
}
.proPlusIconGreen svg {
  font-size: 20px;
  color: green;
  /* margin-top: 10px; */
  cursor: pointer;
}
.modalUserPaymentStatus .programList {
  width: 100%;
}
.modalUserPaymentStatus .programList tr th {
  border: 1px solid #000;
  padding: 5px;
}
.modalUserPaymentStatus .programList tr td {
  border: 1px solid #000;
  padding: 5px;
}

.programList {
  width: 100%; /* Set the table width to 100% */
  border-collapse: collapse; /* Collapse table borders */
}

.programList th,
.programList td {
  padding: 8px;
  border: 1px solid #ddd; /* Add borders to cells */
}

.programList th {
  background-color: #f2f2f2;
}

.programList td {
  white-space: nowrap;
}
.addMyTabs .nav-item {
  margin: 5px;
}
.addMyTabs .nav-item .nav-link {
  border: 1px solid #f66d4a;
  color: #000;
}
.addMyTabs .nav-item .nav-link:hover {
  background-color: #f66d4a;
  color: #fff;
}
.addMyTabs .nav-item .active {
  background-color: #6e12f9;
  color: #fff;
}

.tablePricingGrid table {
  width: 100%;
}
.tablePricingGrid table thead tr th {
  border: 1px solid #000;
  padding: 8px;
  background-color: #6e12f9;
  color: #fff;
}
.tablePricingGrid table tbody tr td {
  border: 1px solid #000;
  padding: 8px;
}
.buttonAlignmentProgram {
  display: flex;
  gap: 10px;
  align-items: center;
}
.loanTapeChanges {
  display: flex;
  align-items: end;
}
.proPlusIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid red;
  width: 177px;
  margin: auto;
  margin-top: 25px;
  border-radius: 3px;
  cursor: pointer;
  padding-bottom: 10px;
  transition: 0.3s;
}
.orderDetailsPageAddIcon .proPlusIcon p {
  margin-bottom: -11px;
  margin-left: 10px;
  font-weight: 600;
  color: red;
}
.orderDetailsPageAddIcon .proPlusIconGreen {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
  width: 177px;
  margin: auto;
  margin-top: 25px;
  border-radius: 3px;
  cursor: pointer;
  padding-bottom: 10px;
  transition: 0.3s;
}
.orderDetailsPageAddIcon .proPlusIconGreen p {
  margin-bottom: -11px;
  margin-left: 10px;
  font-weight: 600;
  color: green;
}
.orgbrd {
  background-color: #f7674c;
  opacity: 1;
}
.deletedIcon {
  color: red;
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
}
.productData .formsec {
  border: 1px solid #f7674c;
  padding: 20px 30px;
  border-radius: 10px;
}
.AddingRowCss,
.AddingRowCssReadonly {
  border: none !important;
  background-color: transparent !important;
}

.orderDetailsPageAddIcon .proPlusIconGreen:hover {
  background-color: #e4ece8;
}
.orderDetailsPageAddIcon .proPlusIcon:hover {
  background-color: #e4ece8;
}
.customrowChangeOrderDetilas {
  /* margin-top: 15px; */
  /* padding: 20px; */
  /* border-radius: 2px;
  border: 1px solid #ccc; */
  border: 1px solid #f47746;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  /* background: #fff; */
  /* min-height: 425px; */
  /* overflow-y: scroll; */
  height: 700px;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid #333333;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: #333333;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #f66d4a;
  border-color: #f66d4a;
}
.disbursedHeaderShow .disTagChange h6 {
  background-color: #198754;
}
.dibsursedTagShow {
  background-color: #1d8102;
  border-radius: 25px;
  padding: 2px 10px;
  width: 100px;
  color: #fff;
  font-weight: 600;
}
.topOrderHeaderBorder {
  border: 1px solid #000;
  padding: 10px;
  border-radius: 2px;
  margin-right: 1px;
}
.showWholeBorder {
  border: 1px solid #f47746;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  /* background: #fff; */
  max-height: 700px;
  height: 700px;
  overflow-y: scroll;
}
.creditLimitHeading p {
  font-weight: 600;
}
.creditLimitHeading {
  margin-bottom: 7px;
  font-weight: 600;
  line-height: 1.5;
  font-size: 14px;
}
.repaymentShowHeading {
  font-weight: 500;
  font-size: 15px;
}
.repymentDetailsPay {
  font-weight: 600;
  font-size: 17px;
  margin-bottom: 4px;
  color: #fc5553;
}
.assignLimitCustom {
  padding: 10px;
  border: 1px solid #000;
  border-radius: 2px;
}
.blueColorStyleSpan {
  color: #0000f9;
}
.orangeColorStyleSpan {
  color: #f66d4a;
}
.sidebarMainMenu {
  position: relative;
}
.togglerButton {
  margin-bottom: 0rem;
  font-size: 23px;
  color: #f7674c !important;
  background-color: transparent;
  width: 35px;
  height: 40px;
  margin-top: 0px;
  border-radius: 4px;
  border: none;
  font-weight: 700;
}
.css-16jesut > .ps-menu-button {
  padding-right: 4px !important;
  padding-left: 4px !important;
}
.css-1t8x7v1 > .ps-menu-button {
  padding-right: 4px !important;
  padding-left: 4px !important;
}
.loanRepaymentExportSec input[type="text"] {
  padding: 6px 6px;
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #ccc;
}
.distributorNamePaymentChange {
  border-top: 1px solid #ccc;
}
.repaymentDistributorMultiple {
  margin-bottom: 0px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 15px;
}
.customUserMearginRepay {
  margin-top: 8px !important;
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-primary {
  color: #fff;
  background-color: #007bff;
}
.badge-teal {
  color: #fff;
  background-color: #17a2b8;
}
.badge-purple {
  color: #fff;
  background-color: #6142ff;
}
.badge-orange {
  color: #fff;
  background-color: #fd7e14;
}

.butnGenrateLimit {
  color: #28a745;
  background: none;
  font-size: 23px;
  font-weight: 800;
  padding: 0px 8px;
  transition: 0.3s all;
  border-radius: 2px;
  border: none;
}
.butnAssignLimit {
  color: #280fe9;
  background: none;
  font-size: 18px;
  font-weight: 800;
  padding: 0px 8px;
  transition: 0.3s all;
  border-radius: 2px;
  border: none;
}
.butnGenrateLimit:hover {
  background-color: #28a745;
  color: #fff;
  padding: 0px 8px;
}

.authorizeLimitButton {
  color: #fff;
  background-color: #f66d4a;
  font-size: 13px;
  font-weight: 600;
  padding: 8px 12px;
  transition: 0.3s all;
  border-radius: 2px;
  border: none;
}
.authorizeLimitButton:hover {
  background-color: #28a745;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  padding: 8px 12px;
  transition: 0.3s all;
  border-radius: 2px;
  border: none;
}
.react-datepicker-wrapper {
  position: relative;
}
.orderFlagUpdate label {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 10px 0;
  cursor: pointer;
}

.orderFlagUpdate input[type="checkbox"] {
  margin-right: 10px;
}
.submitButtonOrderFlag {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 5px;
}
.submitButtonOrderFlag button {
  padding: 10px 25px;
  border: none;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  background-image: linear-gradient(#f7674c, #f47746);
}
.customHeaderTable.distable {
  position: relative;
}
.evaluationSec .card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  /* width: 40%; */
}

.evaluationSec .card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.evaluationSec .indicators {
  min-height: 250px;
  max-height: 250px;
  overflow-y: auto;
}
.evaluationSec .indicators ul {
  padding-left: 20px;
}
/* .evaluationSec .eligibilityTable{min-height: 250px; max-height: 250px; overflow-y: auto;} */
.evaluationSec .repaymentCount {
  min-height: 250px;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid #ccc;
}
.evaluationSec .eligibilityTable {
  min-height: 350px;
  max-height: 350px;
  overflow-y: auto;
  border: 1px solid #ccc;
}
.evaluationSec .mrtop {
  margin-top: 30px;
}
.customrowChangeOrderDetilas {
  position: relative;
}
.customrowChangeOrderDetilas .table > :not(:first-child) {
  border: none;
}
.customrowChangeOrderDetilas .table th {
  background: #f4f4f4;
  color: #000;
}
.customrowChangeOrderDetilas .table th {
  border-bottom: none;
  border: 1px solid #dde4ea;
}
.customrowChangeOrderDetilas .table tbody tr {
  border-bottom: 1px solid #dde4ea;
}
.customrowChangeOrderDetilas .table tbody td {
  border: none;
}
.customrowChangeOrderDetilas .table th {
  width: 150px;
  min-width: 150px;
}
.noMarginSection .brd {
  border: 1px solid #f47746;
  padding: 10px;
  margin-top: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
}
.customrowChangeOrderDetilas .table thead {
  position: sticky;
  top: 0;
  background-color: #f1f1f1;
  z-index: 100;
}
.customrowChangeOrderDetilas .table tbody {
  max-height: 300px;
  overflow: auto;
}

.customrowChangeOrderDetilas .table-responsive {
  width: 100%; /* Adjust width as needed */
  max-height: 600px; /* Set the desired height for the scrollable area */
  overflow-y: auto; /* Enables vertical scrolling */
  border: 1px solid #ddd; /* Optional: adds a border around the table */
  height: 600px;
}
/* .css-1w5esjs-menu{position: absolute; z-index: 10000;} */
/* .customrowChangeOrderDetilas .table tr td:nth-child(2){width: 300px;} */
.typheadsec {
  width: 400px !important;
  min-width: 400px !important;
}
.typproductName {
  width: 250px !important;
  min-width: 250px !important;
}
.customrowChangeOrderDetilas .typheadsec input {
  border: none;
  font-size: 14px;
}
/* .typeaheadData{border-bottom: 1px solid #ccc; padding-bottom: 5px;} */
.customrowChangeOrderDetilas .table tr th:nth-child(1),
.customrowChangeOrderDetilas .table tr th:nth-child(2),
.customrowChangeOrderDetilas .table tr th:nth-child(3),
.customrowChangeOrderDetilas .table tr th:nth-child(4) {
  width: 80px !important;
  min-width: 80px !important;
}
.customrowChangeOrderDetilas .table tr th:nth-child(2),
.customrowChangeOrderDetilas .table tr td:nth-child(2) {
  text-align: center;
}
.customrowChangeOrderDetilas .table .rbt-menu > .dropdown-item:hover {
  background: #418dfb;
  color: #fff;
}
.customrowChangeOrderDetilas .table .rbt-menu > .dropdown-item {
  border-bottom: 1px solid #ccc;
}
.customrowChangeOrderDetilas .table .rbt-menu > .dropdown-item:last-child {
  border-bottom: none;
}
.customrowChangeOrderDetilas .table .dropdown-menu.show {
  width: 480px !important;
}

.customrowChangeOrderDetilas .table .rbt-menu > .dropdown-item:hover .prodName,
.customrowChangeOrderDetilas
  .table
  .rbt-menu
  > .dropdown-item:hover
  .prodBrand {
  color: #fff;
}
.basicDetailsUser .wd {
  width: 30px;
  height: 20px;
  margin-right: 5px;
}
.customrowChangeOrderDetilas .table .dropdown-menu .prodcolor {
  color: rgb(93, 135, 255);
  font-weight: 600;
  background: none;
}
.customrowChangeOrderDetilas .table .dropdown-menu .cateColor {
  color: rgb(255, 174, 31);
  font-weight: 600;
  background: none;
}
.customrowChangeOrderDetilas .table .dropdown-menu .amtcolor {
  color: rgb(73, 190, 255);
  font-weight: 600;
  background: none;
}
.customrowChangeOrderDetilas .table .dropdown-menu .brandcolor {
  color: rgb(19, 222, 185);
  font-weight: 600;
  background: none;
}
.customrowChangeOrderDetilas .table .dropdown-menu .subcatcolor {
  color: #800080;
  font-weight: 600;
  background: none;
}
.customrowChangeOrderDetilas .table .dropdown-menu .distcolor {
  color: #800000;
  font-weight: 600;
  background: none;
}
.customrowChangeOrderDetilas .table .dropdown-menu .name {
  color: #ff5733;
  font-weight: 600;
  background: none;
}
.customrowChangeOrderDetilas .table .dropdown-menu .color {
  color: #808000;
  font-weight: 600;
  background: none;
}
.customrowChangeOrderDetilas .table .dropdown-menu .size {
  color: #000080;
  font-weight: 600;
  background: none;
}
.customrowChangeOrderDetilas .table .dropdown-item:hover .typeaheadData small {
  color: #fff;
}
.customrowChangeOrderDetilas tfoot td {
  padding: 8px 20px;
  font-weight: 600;
  border: 1px solid #dde4ea;
}
.customrowChangeOrderDetilas .table tfoot td:nth-child(2) {
  text-align: left;
}

.basicDetailsUser .prodcolor {
  background: rgb(93, 135, 255);
}
.basicDetailsUser .cateColor {
  background: rgb(255, 174, 31);
}
.basicDetailsUser .amtcolor {
  background: rgb(73, 190, 255);
}
.basicDetailsUser .brandcolor {
  background: rgb(19, 222, 185);
}
.basicDetailsUser .subcatcolor {
  background: #800080;
}
.basicDetailsUser .distcolor {
  background: #800000;
}
.basicDetailsUser .name {
  background: #ff5733;
}
.basicDetailsUser .color {
  background: #808000;
}
.basicDetailsUser .size {
  background: #000080;
}

.customrowChangeOrderDetilas .table .visibleData .prodcolor {
  color: rgb(93, 135, 255);
  background: none;
  white-space: nowrap; /* Prevents text from wrapping to the next line */
  overflow: hidden; /* Hides the overflowing text */
  text-overflow: ellipsis; /* Adds the ellipsis (...) */
  width: 350px; /* Set a fixed width for the container */
  display: block; /* Ensures the element is treated as a block */
}
.customrowChangeOrderDetilas .table .visibleData .cateColor {
  color: rgb(255, 174, 31);
  background: none;
}
.customrowChangeOrderDetilas .table .visibleData .amtcolor {
  color: rgb(73, 190, 255);
  background: none;
}
.customrowChangeOrderDetilas .table .visibleData .brandcolor {
  color: rgb(19, 222, 185);
  background: none;
}
.customrowChangeOrderDetilas .table .visibleData .subcatcolor {
  color: #800080;
  background: none;
}
.customrowChangeOrderDetilas .table .visibleData .distcolor {
  color: #800000;
  background: none;
}
.customrowChangeOrderDetilas .table .visibleData .name {
  color: #ff5733;
  background: none;
}
.customrowChangeOrderDetilas .table .visibleData .color {
  color: #808000;
  background: none;
}
.customrowChangeOrderDetilas .table .visibleData .size {
  color: #000080;
  background: none;
}
.customrowChangeOrderDetilas .table .visibleData {
  font-size: 14px;
  font-weight: 600;
}

.customrowChangeOrderDetilas .table thead tr {
  background: #f4f4f4;
}
.customrowChangeOrderDetilas .table thead th:nth-child(3),
.customrowChangeOrderDetilas .table tbody td:nth-child(3) {
  /* position: sticky; */
  left: 0;
  z-index: 1;
}

.customrowChangeOrderDetilas .table tr td:nth-child(1),
.customrowChangeOrderDetilas .table tr th:nth-child(1) {
  position: sticky;
  left: 0;
  /* background-color: #fff; */
  z-index: 1;
}

.customrowChangeOrderDetilas .table tr td:nth-child(2),
.customrowChangeOrderDetilas .table tr th:nth-child(2) {
  position: sticky;
  left:80px;/* Adjust based on column widths */
  /* background-color: #fff; */
  z-index: 1;
}
.customrowChangeOrderDetilas .table tr td:nth-child(1),
.customrowChangeOrderDetilas .table tr td:nth-child(2),
.customrowChangeOrderDetilas .table tr td:nth-child(3),
.customrowChangeOrderDetilas .table tr td:nth-child(4),
.customrowChangeOrderDetilas .table tr td:nth-child(5) {
  background: #fff;
}
.customrowChangeOrderDetilas .table tr td:nth-child(3),
.customrowChangeOrderDetilas .table tr th:nth-child(3) {
  /* position: sticky; */
  left: 180px; /* Adjust based on column widths */
  /* background-color: #fff; */
  z-index: 10;
}
.customrowChangeOrderDetilas .table tr td:nth-child(4),
.customrowChangeOrderDetilas .table tr th:nth-child(4) {
  /* position: sticky; */
  left: 390px; /* Adjust based on column widths */
  /* background-color: #fff; */
  z-index: 1;
}
.customrowChangeOrderDetilas .table tr td:nth-child(5),
.customrowChangeOrderDetilas .table tr th:nth-child(5) {
  /* position: sticky; */
  left: 600px; /* Adjust based on column widths */
  /* background-color: #fff; */
  z-index: 1;
}
.customrowChangeOrderDetilas .table tr td:nth-child(5) {
  border-right: 1px solid #dde4ea;
}

.customrowChangeOrderDetilas .table tfoot tr td:nth-child(1),
.customrowChangeOrderDetilas .table tfoot tr th:nth-child(1) {
  position: relative;
  background-color: #fff;
  z-index: 0;
}
.customrowChangeOrderDetilas .css-qfc1gj-placeholder {
  font-size : 14px
}
.customrowChangeOrderDetilas .expandButton svg {
  padding: 0px;
  font-size: 40px;
  color: #0d6efd;
  background: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid;
}
.customrowChangeOrderDetilas .expandButton {
  position: absolute;
  right: -27px;
  z-index: 100;
  top: 40%;
  cursor: pointer;
}
.customrowChangeOrderDetilas .expandButton .toggle-button {
  border: none;
  background: none;
}

.orderDetailsSection .right-image {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.orderDetailsSection .right-image img {
  max-width: 100%;
  height: auto;
}

.orderDetailsSection .right-image.slide-in {
  transform: translateX(0%);
  opacity: 1;
  display: block;
}

.orderDetailsSection .right-image.slide-out {
  transform: translateX(100%);
  opacity: 0;
  display: none;
}
.customrowChangeOrderDetilas .table tbody td.border-danger {
  border-bottom: 2px solid;
}
.customrowChangeOrderDetilas .notselect {
  pointer-events: none;
}
.customrowChangeOrderDetilas .table tbody .disabled .proPlusIconGreen svg {
  color: #ccc;
}
.customrowChangeOrderDetilas .viewBody td {
  padding: 8px 20px;
}
.customrowChangeOrderDetilas .table tbody td.quantitytd input,
.customrowChangeOrderDetilas .table tbody td.quantitytd {
  height: 84px;
  text-align: center;
}
#accordionPanelsStayOpenExample .accordion-item.acorrdiannew {
  margin-top: 0;
}
.fileDownloadBtn .downloadIcon {
  font-size: 30px;
  color: #f47746;
}
.orderStatusTable tbody,
.orderStatusTable td,
.orderStatusTable tfoot,
.orderStatusTable th,
.orderStatusTable thead,
.orderStatusTable tr {
  background: #fff;
}
.orderinfosec .react-datepicker-wrapper {
  width: 100%;
}
.dashboardTable tfoot tr td {
  border: 1px solid var(--black-color);
  padding: 8px;
}

.groupLabelChange label span {
  color: red;
}
.updateInventory {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 17px;
}
.inventoryDataSection .formsec .form-label {
  font-weight: 500;
}
.inventoryDataSection .formsec {
  position: relative;
}
.inventoryDataSection .editButtonSet {
  background: transparent;
  border: none;
  color: #f7674c;
  font-size: 25px;
  font-weight: 600;
}
.filtercolorAddInventory .typeaheadData .prodcolor {
  color: rgb(93, 135, 255);
  background: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
  display: block;
  font-weight: 600;
}
.filtercolorAddInventory .typeaheadData .cateColor {
  color: rgb(255, 174, 31);
  background: none;
  font-weight: 600;
}
.filtercolorAddInventory .typeaheadData .brandcolor {
  color: rgb(19, 222, 185);
  background: none;
  font-weight: 600;
}
.filtercolorAddInventory .typeaheadData .subcatcolor {
  color: #800080;
  background: none;
  font-weight: 600;
}
.filtercolorAddInventory .typeaheadData .amtcolor {
  color: rgb(73, 190, 255);
  background: none;
  font-weight: 600;
}
.filtercolorAddInventory .typeaheadData .distcolor {
  color: rgb(1, 13, 19);
  background: none;
  font-weight: 600;
}
.filtercolorAddInventory .dropdown-item {
  border: 1px solid #ccc;
  border-bottom: 0px;
}

.customrowChangeOrderDetilas .inventoryModaldatatable .table .dropdown-menu.show {
  width: 380px !important;
}

.customrowChangeOrderDetilas .typproductName input {
  border: none;
  font-size: 14px;
}