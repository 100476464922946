.sidebar {
    width: 16.5%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #252526;
    color: #fff;
    padding-top: 35px;
    padding-right: 20px;
    padding-left: 12px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    z-index: 99;
  }
  .toggler{
      margin-bottom: 1rem;
      margin-left: 25px !important;
      background: none !important;
      border: none !important;
      color: white !important;
      margin-top: 7px !important;
  }
  .css-nx2aea{
    background-color: unset !important;
  }
  .css-dip3t8 {
    position: relative !important;
    height: 100% !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    z-index: 3 !important;
    background-color:unset !important;
  }
  .css-18unl23{
    background-color:unset !important;
  }
  .ps-menu-button a{
    background-image: linear-gradient(#f7674c,#f47746)  !important;
  }
  .ps-menu-button:hover {
    background:unset  !important;
  }
  .css-honxw6{
    margin-left: -40px !important;
  } 
  .css-jn69v9{
    margin-left: -40px !important;
  }
  .asideMenuBarTab{
    z-index: 4;
  }
  .AppContainer{
    display: flex;
    transition: 0.5s ;
  }
  .logo {
    font-size: 24px;
    font-weight: bold;
    padding: 22px 3px;
    border-radius: 3px;
    text-align: center;
    display: flex;
    align-items: center;
    margin-bottom: -10px;
  }
  
  .menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .menu li {
    margin-bottom: 10px;
    line-height: 2.5;
    padding: 6px 10px;
    border-radius: 3px;
  }

  .menu li:hover{
    background-color: #6E12F9;
  }
  .css-1tqrhto > .ps-menu-button{
    padding-left: 30px !important;
    background-color: #000;
  }
  .menu a {
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    display: flex;
    align-items: center;
  }
  
  .menu a:hover {
    text-decoration: none;
  }
  
  .menu a svg {
    margin-right: 10px;
  }
  .sidebar .active{
    background-color: #6E12F9;
  }
  .main-content {
    flex-grow: 1; /* Allow main-content to grow and take up remaining space */
    padding: 20px; /* Add padding as needed */
    margin-top: 20px;
    width: 80%;
  }
  .logo img{
    max-width: 30px;
    margin-left: 6px;
}
.programFileData svg path{
  fill: white;
}
.programHead{
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 30px;
}.noMarginSection{
  margin-top: -35px;
}
.css-1wvake5 {
  position: fixed !important;
  border-right-width: 1px;
  border-right-style: solid;
  border-color: #efefef;
  -webkit-transition: width,left,right,300ms;
  transition: width,left,right,300ms;
  left: 0;
  top: 0;
  bottom: 0;
  width: 16%;
  padding-right: 0px;
  padding-left: 10px !important;
  background-color: #252526;
  color: white;
}
.css-1097eso {
  display: none !important;
}
.css-wlra1l {
  background-color: #252526 !important;
  width: 14% !important;
}
.css-1wvake5.ps-collapsed{
  width: 64px !important;
    min-width: 64px !important;
}
.ps-submenu-content .ps-menuitem-root:hover{
  background-color: rgb(247, 103, 76) !important;
}
/* ------------------------------------- */
.hamburgerMenuMain .menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  margin-top: 10px;
  margin-left: 5px;
}
.hamburgerMenuMain .line {
  fill: none;
  stroke: white;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.hamburgerMenuMain .line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.hamburgerMenuMain .line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.hamburgerMenuMain .line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.hamburgerMenuMain .opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.hamburgerMenuMain .opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.hamburgerMenuMain .opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}